/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-01-27 20:01:25 */

const Keys = {
  paxSelection: 'paxSelection',
  paxSelectionStaticTitle: 'paxSelectionStaticTitle',
  numberOfGuests: 'numberOfGuests',
  travelDate: 'travelDate',
  date: 'date',
  hotelName: 'hotelName',
  destination: 'destination',
  destinationHotelName: 'destinationHotelName',
  typeInDestination: 'typeInDestination',
  location: 'location',
  submit: 'submit',
  invalidInput: 'invalidInput',
  airport: 'airport',
  travelers: 'travelers',
  type: 'type',
  clear: 'clear',
  edit: 'edit',
  confirm: 'confirm',
  pleaseSelect: 'pleaseSelect',
  hotelStayDates: 'hotelStayDates',
  room: 'room',
  room_plural: 'room_plural',
  adult: 'adult',
  adult_plural: 'adult_plural',
  child: 'child',
  child_plural: 'child_plural',
  select: 'select'
}; export default Keys;